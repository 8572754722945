<template>
  <div class="ml-steps" v-show="stepsToFinish > 0">
    <div class="bb-container bb-container--lista">
      <div class="ml-steps__header">
        <div
          class="bb-accordion"
          @click="toggleWizard = !toggleWizard"
          :class="toggleWizard ? 'bb-accordionn--open' : ''"
        >
          <div class="bb-accordion__title">
            <div class="bb-container bb-container--lista">
              <h2 class="ml-steps__title">
                {{ message }}
                <span class="ml-steps__subtitle">{{ stepsToFinishText }}</span>
              </h2>
            </div>
          </div>
          <span class="bb-accordion__right">
            <img
              src="../../../assets/img/ico/ico-chevron-up.svg"
              loading="lazy"
              alt=""
              width="16px"
              :class="
                toggleWizard
                  ? 'bb-accordion__chevron bb-accordion__chevron--active'
                  : 'bb-accordion__chevron '
              "
            />
          </span>
        </div>
      </div>
    </div>

    <div class="bb-container bb-container--lista bb-container--575-no-padding">
      <transition name="bbFade">
        <div class="list-section__content" v-if="toggleWizard">
          <div class="ml-steps__items">
            <no-ssr>
              <VueSlickCarousel v-if="showCarousel" v-bind="settings">
                <component
                  :is="$utils.capitalize(step) + 'Step'"
                  v-for="step in unfinishedSteps"
                  :key="'unfinished_' + step"
                ></component>
                <component
                  :is="$utils.capitalize(step) + 'Step'"
                  v-for="step in finishedSteps"
                  :key="'finished_' + step"
                ></component>
              </VueSlickCarousel>
            </no-ssr>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NoSSR from "vue-no-ssr";
import VueSlickCarousel from "vue-slick-carousel";

import Features from "../../../Mixins/Features";
import ListTitleStep from "./ListTitleStep";
import PhotoStep from "./PhotoStep";
import WelcomeMessageStep from "./WelcomeMessageStep";
import ChildBirthdayStep from "./ChildBirthdayStep";
import ChildNameStep from "./ChildNameStep";
import HuchaStep from "./HuchaStep";
import InviteStep from "./InviteStep";

export default {
  name: "ListWizard",
  components: {
    VueSlickCarousel,
    "no-ssr": NoSSR,

    ListTitleStep,
    PhotoStep,
    WelcomeMessageStep,
    ChildBirthdayStep,
    ChildNameStep,
    HuchaStep,
    InviteStep,
  },
  mixins: [
    Features,
  ],
  data() {
    return {
      showCarousel: false,
      toggleWizard: true,
      isMainUser: true,
      steps: [],
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      listType: "ownerListType",
      user: "user",
      contributionsFlag: "contributionsFlag",
    }),
    message() {
      return this.stepsToFinish > 0
        ? this.$t("mylist.finishToSetupYourList")
        : this.$t("mylist.setupYourList");
    },
    finishedSteps() {
      return (this.steps || []).filter((step) => {
        return this[step + "Step"];
      });
    },
    unfinishedSteps() {
      return (this.steps || []).filter((step) => {
        return !this[step + "Step"];
      });
    },
    listTitleStep() {
      return (
        this.list.title !== "" &&
        this.list.title != null &&
        this.list.title !== this.$t("mylist.defaultListName")
      );
    },
    photoStep() {
      return this.list.photo !== "" && this.list.photo != null && this.list.photo !== "default-profile-image.jpg";
    },
    welcomeMessageStep() {
      return this.user?.welcomeMessage !== "" && this.user?.welcomeMessage != null;
    },
    childBirthdayStep() {
      return !this.isBabyList || (this.user?.childBirthday !== "" && this.user?.childBirthday != null);
    },
    childNameStep() {
      return !this.isBabyList || (this.user?.childName !== "" && this.user?.childName != null);
    },
    inviteStep() {
      return (
        this.user?.partner != null ||
        this.user?.collaboratorRefused ||
        this.user?.invitationText !== null
      );
    },
    huchaStep() {
      const stripeAccountId = this.user?.stripeAccountId || "";
      return (stripeAccountId !== null && stripeAccountId.trim() !== "") || this.user.contributionsRefused;
    },
    stepsToFinish() {
      //let stepsToFinish = this.isMainUser !== false ? 7 : 6;
      let stepsToFinish = this.isMainUser !== false ? 6 : 5;
      if (this.isMainUser && !this.includeCollaboratorStep) {
        stepsToFinish--;
      }

      if (!this.contributionsFlag || !this.featureContributionsAvailable) stepsToFinish--;
      if (this.listTitleStep) stepsToFinish--;
      if (this.photoStep) stepsToFinish--;
      if (this.welcomeMessageStep) stepsToFinish--;
      if (this.childBirthdayStep) stepsToFinish--;
      if (this.childNameStep) stepsToFinish--;
      if (this.inviteStep) stepsToFinish--;
      if (this.huchaStep) stepsToFinish--;
      return stepsToFinish;
    },
    /*
    stepsToFinish() {
      // TODO: Ideally, all this method should be a single line:
      //
      //     return this.unfinishedSteps.length;
      //
      // It can't be done that way yet because there's special handling for the
      // `Hucha` and `Invite` steps, where they appear as unfinished, but they
      // are hidden using different logic.

      // Assume all visible steps are pending.
      //
      // Any step in this array will be counted as pending.
      let pendingSteps = (this.steps || []);

      // Check every step if it's done, and remove it from the array of pending
      // steps.
      pendingSteps = pendingSteps.filter((stepName) => {
        // NOTE: If a step is done, return `false`.

        switch (stepName) {
        case "listTitle":
          return !this.listTitleStep;
        case "photo":
          return !this.photoStep;
        case "welcomeMessage":
          return !this.welcomeMessageStep;
        case "childBirthday":
          return !this.childBirthdayStep;
        case "childName":
          return !this.childNameStep;
        case "Invite":
          return !this.inviteStep;
        case "Hucha":
          return !this.huchaStep;
        default:
          console.error(`Unknown step ${stepName}, we don't know if it's done or not.`);

          return true;
        }
      });

      return pendingSteps.length;
    },
    */
    stepsToFinishText() {
      if (this.stepsToFinish === 0) {
        return "";
      }
      if (this.stepsToFinish === 1) {
        return "(" + this.$t("mylist.pendingStep") + ")"
      }
      return "(" + this.$t("mylist.pendingSteps", { steps: this.stepsToFinish }) + ")";
    },
    isBabyList() {
      return this.$utils.isBabyList(this.listType);
    },
    includeCollaboratorStep() {
      return this.isBabyList || this.listType === "wedding";
    },
  },
  methods: {
    async getCollaboratorDataIfApp() {
      if (this.$utils.isApp()) {
        console.log("getCollaboratorDataIfApp");
        let u = await this.$store.dispatch("getConnectedUser");
        var auth_token = this.$getAuthTokenFromCookies();
        this.$store.commit("setAuthToken", auth_token);
        console.log(u);
      }
      return true;
    },
    async loadWizard() {
      console.log(this.user);
      // USER IS STORED IN COOKIES ONLY AVAILABLE AT SERVER SIDE VIA VUEX STORE

      //if (true) {
      //if (this.$serverSide()) {
      //if(true) {
      if (this.$utils.isApp()) {
        this.$store.commit("setUserOnServerSide", this.$getUserFromCookies());
        console.log("1 ", this.user.isMainUser);
      }

      // Needed to update `user` with additional properties.
      await this.$store.dispatch("getConnectedUser");

      //await this.getCollaboratorDataIfApp()
      this.steps = [
        "listTitle",
        "photo",
        "welcomeMessage",
        this.isBabyList ? "childBirthday" : null,
        this.isBabyList ? "childName" : null,
      ].filter(step => !!step);
      if (this.user?.isMainUser !== false) {
        // if (this.contributionsFlag && this.featureContributionsAvailable) {
        //   this.steps.push("Hucha");
        // }
        if (this.includeCollaboratorStep) {
          this.steps.push("Invite");
        }
      }
      this.isMainUser = this.user?.isMainUser;

      this.settings = {
        draggable: false,
        centerMode: false,
        slidesToShow: this.user?.isMainUser !== false ? 6 : 5,
        currentIndex: 0,
        arrows: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 4000,
            settings: {
              slidesToShow: 4,
              centerMode: true,
              infinite: true,
              draggable: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerMode: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              draggable: true,
              arrows: false,
            },
          },
        ],
      };
      if (this.stepsToFinish > 0) {
        await import("vue-slick-carousel/dist/vue-slick-carousel.css");
        await import("vue-slick-carousel/dist/vue-slick-carousel-theme.css");
      }

      this.showCarousel = true;
    }
  },
  async mounted() {
    await this.loadWizard();
  },
  watch: {
    list: {
      handler() {
        this.loadWizard();
      },
      deep: true,
    },
  }
};
</script>
